var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Danh sách phần quà",
        visible: _vm.visbile,
        width: "600px",
        top: "15",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visbile = $event
        },
      },
    },
    [
      _c(
        "ul",
        _vm._l(_vm.data, function (item, index) {
          return _c("li", { key: index }, [
            _c("img", {
              attrs: { src: _vm.$baseUrlMedia + item.thumbnail, alt: "" },
            }),
            _c("div", [
              _c("label", { attrs: { for: "" } }, [_vm._v(_vm._s(item.name))]),
              _c("br"),
              _c("span", { attrs: { for: "" } }, [
                _vm._v(" " + _vm._s(item.description)),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }