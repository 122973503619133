var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "text-align": "center" } }, [
    _c("img", {
      attrs: { src: require("@/assets/images/Logo/logo.png"), alt: "" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }