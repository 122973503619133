var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tutorial reward" },
    [
      _c("div", { staticClass: "text-center" }, [
        _c("span", { staticClass: "name" }, [_vm._v("Quà bạn đã thu thập")]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/line-yellow/LineHeader/Large.png"),
            alt: "",
            width: "220",
          },
        }),
      ]),
      _c("div", { staticClass: "content" }, [
        !this.list.length
          ? _c("span", { staticClass: "no-reward" }, [
              _vm._v("Bạn chưa nhận được phần quà nào."),
            ])
          : _c(
              "div",
              { staticClass: "list-ward" },
              _vm._l(_vm.list, function (item, index) {
                return _c("img", {
                  key: index,
                  staticClass: "image-ward",
                  attrs: { src: _vm.$baseUrlMedia + item.thumbnail, alt: "" },
                })
              }),
              0
            ),
      ]),
      _c("ListRewardModal", { ref: "list-reward-modal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }