var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tutorial" }, [
    _c("div", { staticClass: "text-center" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Quà tặng tỏa sắc")]),
      _c("img", {
        attrs: {
          src: require("@/assets/images/line-yellow/LineHeader/Large.png"),
          alt: "",
          width: "220",
        },
      }),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("ul", { staticClass: "content-list" }, [
        _c("li", [
          _vm._v("Diamond FLX Pro 2019 vùng mắt trị giá "),
          _c("b", [_vm._v("100,000,000 vnđ ")]),
        ]),
        _c("li", [
          _vm._v("Voucher làm đẹp trị giá "),
          _c("b", [_vm._v("20,000,000 vnđ")]),
        ]),
        _c("li", [
          _vm._v("Ủ trắng mặt tinh chất hoa anh đào "),
          _c("b", [_vm._v("5,000,000 vnđ")]),
        ]),
        _c("li", [
          _vm._v("Trẻ hóa da mặt Super Hifu vùng mặt trị giá "),
          _c("b", [_vm._v("3,000,000 vnđ")]),
        ]),
        _c("li", [
          _vm._v("Voucher làm đẹp trị giá "),
          _c("b", [_vm._v("2,000,000 vnđ")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }