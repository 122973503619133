"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pieceApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var pieceApi = {
  findAll: function findAll() {
    return (0, _request.default)({
      url: '/piece'
    });
  }
};
exports.pieceApi = pieceApi;