var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "game-demo" } },
    [
      _c("Shell", { attrs: { isMobile: _vm.isMobile } }),
      _vm._m(0),
      !_vm.isMobile
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [_c("Tutorial")], 1),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("Game", {
                    attrs: {
                      isPlaying: _vm.isPlaying,
                      handleStop: _vm.handleStop,
                      handleStart: _vm.handleStart,
                      turnAvailable: _vm.turnAvailable,
                      data: _vm.data,
                    },
                  }),
                  _c("Reward", {
                    staticStyle: { "margin-top": "40px", padding: "30px" },
                  }),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 8 } }, [_c("ListReward")], 1),
            ],
            1
          )
        : [
            _c("Game", {
              attrs: {
                isPlaying: _vm.isPlaying,
                handleStop: _vm.handleStop,
                handleStart: _vm.handleStart,
                turnAvailable: _vm.turnAvailable,
                data: _vm.data,
              },
            }),
            _c("Tutorial"),
            _c("Reward", { staticStyle: { "margin-top": "40px" } }),
            _c("ListReward"),
          ],
      _c("ResultModal", { ref: "result-modal", attrs: { result: _vm.result } }),
      _c("LoginModal", {
        ref: "login-modal",
        attrs: {
          onLoginSuccess: _vm.getInfo,
          isLogined: _vm.isLogined,
          loadingFb: _vm.loadingFb,
        },
        on: {
          "update:isLogined": function ($event) {
            _vm.isLogined = $event
          },
          "update:is-logined": function ($event) {
            _vm.isLogined = $event
          },
        },
      }),
      _c(
        "a",
        {
          staticStyle: { color: "white", "font-size": "12px" },
          attrs: { href: "https://bmd.com.vn/" },
        },
        [_vm._v("Developed by bmd.com.vn")]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo text-center" }, [
      _c("h2", { staticStyle: { color: "rgb(217, 179, 0)" } }, [
        _vm._v("SĂN NGỌC TỎA SẮC "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }