var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "result-modal",
      attrs: {
        title: "Kết quả",
        visible: _vm.visbile,
        width: "600px",
        top: "10vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visbile = $event
        },
      },
    },
    [
      _c("div", { staticClass: "result " }, [
        _c("img", {
          staticClass: "animate-scale",
          attrs: { src: require("@/assets/images/ribbon.png"), width: "400" },
        }),
        _c("div", { staticClass: "image" }, [
          _c("img", {
            attrs: {
              src: _vm.$baseUrlMedia + _vm.result.thumbnail,
              alt: "",
              width: "200",
            },
          }),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "text-center",
          staticStyle: {
            "margin-top": "30px",
            color: "#D9B300",
            "font-size": "30px",
            display: "block",
            "text-transform": "uppercase",
            "font-family": "UTM_Bebas",
          },
        },
        [_vm._v("Cảm ơn đã tham gia minigame")]
      ),
      _c(
        "div",
        { staticClass: "text-center", staticStyle: { "margin-top": "30px" } },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-weight": "bold",
                "font-size": "20px",
                color: "black",
              },
            },
            [_vm._v(_vm._s(_vm.result.name))]
          ),
          _c("br"),
          _c("span", { staticStyle: { "font-size": "14px", color: "black" } }, [
            _vm._v(_vm._s(_vm.result.description)),
          ]),
        ]
      ),
      !_vm.isShared
        ? _c(
            "el-button",
            {
              staticClass: "btn-sm",
              staticStyle: { background: "#2E3192" },
              attrs: { type: "warning", loading: _vm.loading },
              on: { click: _vm.handleShare },
            },
            [_c("span", [_vm._v("Share minigame để nhận thêm lượt chơi!")])]
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          staticClass: "btn-sm",
          staticStyle: { background: "#D9B300" },
          attrs: { type: "warning" },
          on: { click: _vm.handleClose },
        },
        [_vm._v("TÌM HIỂU VỀ DỊCH VỤ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }