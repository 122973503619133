"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _moment = _interopRequireDefault(require("moment"));

var Expire = "EXPIRE";
var TokenKey = "token";

function getToken() {
  var current = (0, _moment.default)().valueOf();
  var expire = localStorage.getItem(Expire);
  if (expire < current) setToken("");
  return localStorage.getItem(TokenKey);
}

function setToken(token) {
  var expire = (0, _moment.default)().add(1, "days").valueOf();
  localStorage.setItem(Expire, expire);
  return localStorage.setItem(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}