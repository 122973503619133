import { render, staticRenderFns } from "./ListRewardModal.vue?vue&type=template&id=725cbd12&scoped=true&"
import script from "./ListRewardModal.vue?vue&type=script&lang=ts&"
export * from "./ListRewardModal.vue?vue&type=script&lang=ts&"
import style0 from "./ListRewardModal.vue?vue&type=style&index=0&id=725cbd12&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725cbd12",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('725cbd12')) {
      api.createRecord('725cbd12', component.options)
    } else {
      api.reload('725cbd12', component.options)
    }
    module.hot.accept("./ListRewardModal.vue?vue&type=template&id=725cbd12&scoped=true&", function () {
      api.rerender('725cbd12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/game-demo/components/ListRewardModal.vue"
export default component.exports