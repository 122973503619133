"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scrollTo = scrollTo;

require("core-js/modules/es.array.slice.js");

var _moment = _interopRequireDefault(require("moment"));

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;

  if (t < 1) {
    return c / 2 * t * t + b;
  }

  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
};

var requestAnimFrame = function () {
  return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
    window.setTimeout(callback, 1000 / 60);
  };
}();
/**
 * @param {number} to
 * @param {number} duration
 * @param {Function} callback
 */


function scrollTo(el) {
  var stepTop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var stepLeft = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var e = arguments.length > 3 ? arguments[3] : undefined;
  var topStyle = el.style.top;
  var leftStyle = el.style.left;
  var lastTop = !el.style.top ? 0 : topStyle.slice(0, topStyle.indexOf("px"));
  var lastLeft = !el.style.left ? 0 : leftStyle.slice(0, leftStyle.indexOf("px"));
  var top = parseFloat(lastTop);
  var left = parseFloat(lastLeft);

  var animateScroll = function animateScroll() {
    top += stepTop * 2.8;
    left += stepLeft * 2.8;

    if (top < 0) {
      stepTop = Math.abs(stepTop);
    }

    if (top > 280) {
      stepTop = -stepTop;
    }

    if (left < 0) {
      stepLeft = Math.abs(stepLeft);
    }

    if (left > 195) {
      stepLeft = -stepLeft;
    }

    el.style.top = "".concat(top, "px");
    el.style.left = "".concat(left, "px");
    e.id = requestAnimFrame(animateScroll);
  };

  e.id = requestAnimFrame(animateScroll);
}