"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.TurnStatus = void 0;

require("core-js/modules/es.array.map.js");

var _turn = require("@/api/turn");

var TurnStatus;
exports.TurnStatus = TurnStatus;

(function (TurnStatus) {
  TurnStatus["Pending"] = "PENDING";
  TurnStatus["Reward"] = "REWARD";
  TurnStatus["Contact"] = "CONTACT";
  TurnStatus["GetReward"] = "GET_REWARD";
})(TurnStatus || (exports.TurnStatus = TurnStatus = {}));

var state = function state() {
  return {
    listAvailable: [],
    list: []
  };
};

var mutations = {
  setListAvailable: function setListAvailable(state, list) {
    state.listAvailable = list;
  },
  setList: function setList(state, list) {
    state.list = list;
  }
};
var actions = {
  fetchList: function fetchList(_ref) {
    var commit = _ref.commit;

    _turn.turnApi.findAll().then(function (res) {
      var data = res.data.turns.map(function (e) {
        return e.piece;
      });
      commit('setList', data);
    });
  },
  fetchAvailable: function fetchAvailable(_ref2) {
    var commit = _ref2.commit;

    _turn.turnApi.available().then(function (res) {
      commit('setListAvailable', res.data.turns);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;