"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

var _vue = _interopRequireDefault(require("vue"));

var _vueAwesomeSwiper = _interopRequireDefault(require("vue-awesome-swiper"));

require("swiper/css/swiper.min.css");

_vue.default.use(_vueAwesomeSwiper.default
/* { default options with global component } */
);