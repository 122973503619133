"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var authApi = {
  register: function register(data) {
    return (0, _request.default)({
      url: '/auth/register',
      method: 'post',
      data: data
    });
  },
  checkExist: function checkExist(fbToken) {
    return (0, _request.default)({
      url: '/auth/exist',
      method: 'post',
      data: {
        fbToken: fbToken
      }
    });
  },
  checkPhone: function checkPhone(phone) {
    return (0, _request.default)({
      url: '/auth/exist',
      method: 'post',
      data: {
        phone: phone
      }
    });
  },
  getInfo: function getInfo() {
    return (0, _request.default)({
      url: '/auth/profile',
      method: 'get'
    });
  },
  share: function share() {
    return (0, _request.default)({
      url: '/auth/share',
      method: 'get'
    });
  }
};
exports.authApi = authApi;