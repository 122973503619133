var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tutorial" }, [
    _c("div", { staticClass: "text-center" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Liên hệ")]),
      _c("img", {
        attrs: {
          src: require("@/assets/images/line-yellow/LineHeader/Large.png"),
          alt: "",
          width: "220",
        },
      }),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("ul", { staticClass: "content-list" }, [
        _c("li", { staticClass: "content-item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/Icon/Call.png"), alt: "" },
          }),
          _c("span", { staticClass: "label" }, [_vm._v("Hotline")]),
          _c(
            "a",
            {
              staticStyle: { color: "#D9B300", "font-weight": "bold" },
              attrs: { href: "tel:*3232" },
            },
            [_vm._v("*3232")]
          ),
        ]),
        _c("li", { staticClass: "content-item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("@/assets/images/Icon/Facebook.png"),
              alt: "",
            },
          }),
          _vm._m(0),
        ]),
        _c("li", { staticClass: "content-item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/Icon/Zalo.png"), alt: "" },
          }),
          _vm._m(1),
        ]),
        _c("li", { staticClass: "content-item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("@/assets/images/Icon/Youtube.png"),
              alt: "",
            },
          }),
          _vm._m(2),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label" }, [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.facebook.com/ngocdungbeautycenter/",
          },
        },
        [_vm._v("/ngocdungbeautycenter/")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label" }, [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://zalo.me/3255613806342757641",
          },
        },
        [_vm._v("/ngocdungbeautycenter/")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label" }, [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.youtube.com/channel/UCNXAGXPeAp41PMxanjk1BlA",
          },
        },
        [_vm._v("/ngocdungbeautycenter/")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }