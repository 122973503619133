"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.turnApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var turnApi = {
  findAll: function findAll(data) {
    return (0, _request.default)({
      url: '/turn/reward'
    });
  },
  available: function available() {
    return (0, _request.default)({
      url: '/turn/available'
    });
  },
  turn: function turn(id) {
    return (0, _request.default)({
      url: "/turn/".concat(id),
      method: 'post'
    });
  }
};
exports.turnApi = turnApi;