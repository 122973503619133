var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tutorial" },
    [
      _c("div", { staticClass: "text-center" }, [
        _c("img", {
          staticClass: "ndln",
          staticStyle: { "border-radius": "8px" },
          attrs: {
            src: require("@/assets/images/ndln-5.png"),
            alt: "",
            width: "500",
          },
        }),
      ]),
      _c("ListRewardModal", { ref: "list-reward-modal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }