var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Đăng nhập",
        visible: _vm.visbile,
        width: "400px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visbile = $event
        },
      },
    },
    [
      !_vm.isLogined
        ? _c(
            "div",
            [
              !_vm.visibleLoginPhone
                ? _c(
                    "div",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            background: "#6b5903",
                            border: "#6b5903",
                            color: "white",
                            "margin-bottom": "20px",
                          },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.visibleLoginPhone = true
                            },
                          },
                        },
                        [_vm._v(" Đăng nhập bằng số điện thoại ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-fb",
                          staticStyle: { width: "190px", "margin-left": "0" },
                          attrs: { loading: _vm.loadingFb, type: "primary" },
                          on: { click: _vm.handleLogin },
                        },
                        [_vm._v("Đăng nhập bằng facebook")]
                      ),
                    ],
                    1
                  )
                : [
                    _c(
                      "h3",
                      {
                        staticStyle: {
                          color: "black",
                          "word-break": "break-word",
                          "text-align": "center",
                        },
                      },
                      [_vm._v("Bạn vui lòng nhập số điện thoại")]
                    ),
                    _c(
                      "el-form",
                      {
                        ref: "form-check",
                        attrs: {
                          rules: _vm.rules,
                          model: _vm.form,
                          "label-position": "top",
                        },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.checkExistPhone($event)
                          },
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Số điện thoại", prop: "phone" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Nhập số điện thoại" },
                              model: {
                                value: _vm.form.phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "phone", $$v)
                                },
                                expression: "form.phone",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-form-item", [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { flex: "1", height: "40px" },
                                  on: {
                                    click: function ($event) {
                                      _vm.visibleLoginPhone = false
                                    },
                                  },
                                },
                                [_vm._v("QUAY LẠI")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { flex: "1", height: "40px" },
                                  attrs: {
                                    type: "warning",
                                    loading: _vm.loadingCheck,
                                  },
                                  on: { click: _vm.checkExistPhone },
                                },
                                [_vm._v("TIẾP TỤC")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
            ],
            2
          )
        : _c(
            "div",
            [
              _c(
                "h3",
                {
                  staticStyle: {
                    color: "black",
                    "word-break": "break-word",
                    "text-align": "center",
                  },
                },
                [
                  _vm._v(
                    "Bạn vui lòng nhập thêm thông tin bên dưới để tiếp tục"
                  ),
                ]
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-position": "top",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.handleSignup($event)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Họ tên", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số điện thoại", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.refCode
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Mã giới thiệu" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", placeholder: "" },
                            model: {
                              value: _vm.form.refCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "refCode", $$v)
                              },
                              expression: "form.refCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "40px", width: "100%" },
                          attrs: {
                            type: "warning",
                            loading: _vm.loadingRegister,
                          },
                          on: { click: _vm.handleSignup },
                        },
                        [_vm._v("XÁC NHẬN")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }