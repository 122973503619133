"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.findOne = findOne;
exports.getInfo = getInfo;
exports.getList = getList;
exports.login = login;
exports.logout = logout;
exports.reset = reset;
exports.update = update;
exports.updatePassword = updatePassword;

var _request = _interopRequireDefault(require("@/utils/request"));

function login(data) {
  return (0, _request.default)({
    url: "/auth/login",
    method: "post",
    data: data
  });
}

function getInfo() {
  return (0, _request.default)({
    url: "/auth/profile",
    method: "get"
  });
}

function reset(id, data) {
  return (0, _request.default)({
    url: "/staff/".concat(id, "/resetPassword"),
    method: "post",
    data: data
  });
}

function findOne(userId) {
  return (0, _request.default)({
    url: "/staff/".concat(userId),
    method: "get"
  });
}

function updatePassword(data) {
  return (0, _request.default)({
    url: "/auth/password/update",
    method: "post",
    data: data
  });
}

function getList(listQuery) {
  return (0, _request.default)({
    url: "/staff",
    method: "get",
    params: listQuery
  });
}

function add(data) {
  return (0, _request.default)({
    url: "/staff",
    method: "post",
    data: data
  });
}

function update(userId, data) {
  return (0, _request.default)({
    url: "/staff/".concat(userId, "/update"),
    method: "post",
    data: data
  });
}

function logout() {
  return (0, _request.default)({
    url: "/user/logout",
    method: "post"
  });
}