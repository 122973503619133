"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;

require("core-js/modules/es.object.to-string.js");

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _blank = _interopRequireDefault(require("@/layout/blank.vue"));

_vue.default.use(_vueRouter.default);
/* Layout */


/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/",
  component: _blank.default,
  redirect: "/",
  children: [{
    path: "",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/game-demo"));
      });
    },
    name: "Game",
    meta: {
      title: "Săn ngọc tỏa sắc"
    }
  }]
}, {
  path: "/demo",
  component: _blank.default,
  redirect: "/demo",
  children: [{
    path: "",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/game-demo"));
      });
    },
    name: "Game-demo",
    meta: {
      title: "Săn ngọc tỏa sắc"
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [// 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;