var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "background-off" },
        [
          !_vm.isPlaying
            ? _c(
                "el-button",
                {
                  staticClass: "start-btn start-btn2",
                  attrs: { type: "warning" },
                  on: { click: _vm.handleStart },
                },
                [_c("span", [_vm._v("săn ngọc ngay")])]
              )
            : _vm._e(),
          _c("img", {
            staticClass: "image-back",
            attrs: { src: require("@/assets/images/back.png"), alt: "" },
          }),
          _c("div", { staticClass: "image-box" }, [
            _c("img", {
              staticClass: "image",
              attrs: {
                src: _vm.isPlaying
                  ? require("@/assets/images/front-on/Front_On.png")
                  : require("@/assets/images/front-off/Front_Off.png"),
              },
            }),
          ]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isPlaying,
            expression: "isPlaying",
          },
        ],
      },
      [
        _c(
          "div",
          { staticClass: "block-main" },
          _vm._l(_vm.data, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "img-box",
                class: "img-box-" + item.globalId,
                style:
                  "top: " +
                  (item.top != null && item.top + "px") +
                  ";left: " +
                  (item.left != null && item.left + "px"),
                on: { click: _vm.handleStop },
              },
              [
                _c("img", {
                  staticClass: "shell-img",
                  attrs: { src: require("@/assets/images/shell.png") },
                }),
              ]
            )
          }),
          0
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "text-center btn-group" },
      [
        _vm.isPlaying
          ? _c(
              "span",
              {
                staticStyle: {
                  color: "rgb(217, 179, 0)",
                  "font-weight": "bold",
                  cursor: "pointer",
                  display: "block",
                  "margin-bottom": "10px",
                },
              },
              [_vm._v("Chọn vào viên Ngọc chị thích nhé!")]
            )
          : _vm._e(),
        _c("span", { staticClass: "turn-info" }, [
          _vm._v(" Bạn còn "),
          _c("span", { staticClass: "turn-quantity" }, [
            _vm._v(_vm._s(_vm.turnAvailable.length) + " lượt"),
          ]),
          _vm._v(" chọn nữa "),
        ]),
        _vm.userInfo
          ? _c("div", [
              _c(
                "span",
                {
                  staticClass: "turn-info",
                  staticStyle: {
                    color: "rgb(217, 179, 0)",
                    "font-weight": "bold",
                    cursor: "pointer",
                  },
                  on: { click: _vm.handleShare },
                },
                [
                  _vm._v(
                    _vm._s(
                      !_vm.userInfo.isShared
                        ? "Chia sẻ để nhận thêm lượt chơi"
                        : "Mời bạn bè đăng ký để có thêm lượt chơi"
                    )
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm.userInfo
          ? _c(
              "el-button",
              {
                staticClass: "start-btn",
                attrs: { type: "warning" },
                on: { click: _vm.handleShare },
              },
              [_c("span", [_vm._v("CHIA SẺ NGAY")])]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }