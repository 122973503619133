"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_web-user/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.divisionApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var divisionApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/division",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/division",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/division/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/division/".concat(id, "/delete"),
      method: "post"
    });
  }
};
exports.divisionApi = divisionApi;