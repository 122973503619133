var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tutorial image-list" }, [
    _c(
      "div",
      { staticStyle: { "margin-top": "20px" } },
      [
        _c(
          "swiper",
          { ref: "mySwiper", attrs: { options: _vm.swiperOptions } },
          [
            _vm._l(_vm.imageList, function (item, index) {
              return _c("swiper-slide", { key: index }, [
                _c("img", {
                  staticClass: "image-list-item",
                  attrs: { src: item, alt: "" },
                }),
              ])
            }),
            _c("div", {
              staticClass: "swiper-pagination",
              attrs: { slot: "pagination" },
              slot: "pagination",
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }