var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isMobile
      ? _c("div", [
          _c("img", {
            staticStyle: { left: "400px", top: "300px" },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              left: "300px",
              top: "20px",
              transform: "rotate(250deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: { right: "100px", top: "20px" },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              right: "350px",
              top: "0px",
              transform: "rotate(150deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              left: "100px",
              bottom: "110px",
              transform: "rotate(45deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              left: "350px",
              bottom: "100px",
              transform: "rotate(270deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              right: "100px",
              bottom: "210px",
              transform: "rotate(200deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              right: "200px",
              bottom: "80px",
              transform: "rotate(150deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              right: "300px",
              bottom: "180px",
              transform: "rotate(180deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
        ])
      : _c("div", [
          _c("img", {
            staticStyle: {
              left: "-17px",
              top: "110px",
              transform: "rotate(180deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              left: "100px",
              top: "270px",
              transform: "rotate(150deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              left: "-17px",
              top: "550px",
              transform: "rotate(80deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              right: "-10px",
              top: "110px",
              transform: "rotate(250deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
          _c("img", {
            staticStyle: {
              right: "17px",
              top: "370px",
              transform: "rotate(90deg)",
            },
            attrs: { src: _vm.image, alt: "" },
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }